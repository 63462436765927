<template>
  <div class="home">
    <Stage :slides="stage_images" showInfo/>
    <b-container fluid="xl" style="padding: 1em;">
      <b-row no-gutters>
        <ThemeContainer v-for="theme in themes" :key="theme.title" :themeObject="theme"/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/units/Stage.vue'
import ThemeContainer from '@/components/blocks/ThemeContainer.vue'

export default {
  name: 'Home',
  components: {
    Stage,
    ThemeContainer
  },
  data() {
    return {
      stage_images: [
        require("@/assets/images/home01.jpg")
        // require("@/assets/images/bathroom01.jpg"),
        // require("@/assets/images/bathroom03.jpg")
      ]
    }
  },
  computed: {
    themes() {
      return this.$store.getters.getThemes
    }
  }
}
</script>
