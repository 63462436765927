<template>
  <b-container fluid="xl" class="logo-container">
    <div class="img-container">
      <img src="@/assets/images/innungsfachbetrieb.png" />
    </div>
    <div class="text-container">
      <img src="@/assets/images/berndmueller.png" v-on:click="changeView('home')" />
      <small>{{tel}}</small>
      <a class="t-center" :href="'mailto:'+email"><small>{{email}}</small></a>
    </div>
    <div class="img-container">
      <img src="@/assets/images/shk.png" />
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'HeaderLogos',
  props: {
    tel: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
  },
  methods: {
    changeView(view){
      this.$store.commit('setView', view)
    }
  }
}
</script>

<style lang="scss" scoped>
  a, a:hover, a:active, a:visited {
    text-decoration: none;
    color: #333;
  }
  .logo-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 1em;
    margin: auto;
    & .img-container {
      width: 100px;
      & img {
        width: 100%;
      }
    }
    & .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      @media screen and (min-width: 450px) {
        width: 80%;
      }
      @media screen and (min-width: 768px) {
        width: 60%;
      }
      & img {
        max-width: 100%;
        width: 30%;
        margin: 1em 0;
      }
    }
  }
</style>