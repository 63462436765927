<template>
  <div calss="main">
      <Home v-if="getView===''||getView==='home'" />
      <Heating v-else-if="getView==='heating'" />
      <Plumber v-else-if="getView==='plumber'" />
      <Roof v-else-if="getView==='roof'" />
      <Sanitary v-else-if="getView==='sanitary'" />

      <Imprint v-else-if="getView==='imprint'" />
      <DataSecurity v-else-if="getView==='dataSec'" />
  </div>
</template>

<script>
import Home from './Home.vue';
import Heating from './Heating.vue';
import Plumber from './Plumber.vue';
import Roof from './Roof.vue';
import Sanitary from './Sanitary.vue';
import Imprint from './Imprint.vue';
import DataSecurity from './DataSecurity'

export default {
  components: {
    Home,
    Heating,
    Plumber,
    Roof,
    Sanitary,
    Imprint,
    DataSecurity
  },
  computed: {
    getView() {
      const view = this.$store.getters.getView;
      return view
    }
  }
}
</script>