<template>
  <b-card title="Unsere Öffnungzeiten" class="card__times" align="left">
    <b-card-text>
      <table class="card__times__table">
        <tbody>
          <tr v-for="time in openingTimes" :key="time.title">
            <td class="card__times__head">{{ time.title }}</td>
            <td class="card__times__body">{{ time.times }}</td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    openingTimes: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &__times {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 0;
    background: rgba(51, 51, 51, 0.5);
    color: white;
    & .card-title {
      display: none;
      @media screen and (min-width: 450px) {
        display: block;
      }
    }
    @media screen and (min-width: 768px) {
      left: 2rem;
      width: 50%;
      top: 3rem;
      height: max-content;
    }
    @media screen and (min-width: 900px) {
      width: 33%;
    }
    &__table {
      width: 100%;
    }
    &__head {
      text-align: left;
    }
    &__body {
      text-align: right;
    }
  }
}
</style>
