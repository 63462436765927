<template>
  <div class="roof">
    <Stage :slides="stage_images" showInfo/>
    <b-container style="padding: 1.5rem 0">
      <b-card title="Unsere Leistungen im Überblick">
        <b-card-text>
          Wartung und Erneuerung Ihrer Dacheindeckung <br />
          Dachtypen: Flachdach und Steildach    
        </b-card-text>
        <b-card-text>Wir übernehmen alle Nebenarbeiten wie z.B. Dachdämmung, Zimmerei, Trockenbau</b-card-text>
        <b-card-text>Haben Sie Fragen? Kontaktieren Sie uns gerne telefonisch oder <a :href="'mailto:'+getMail"><small>per E-Mail</small></a>.</b-card-text>
      </b-card>
      <CardImg 
        :cardImg="require('@/assets/images/pre_post_house.jpeg')" 
        title="Vorher - Nachher" 
        text="Die Sanierung bestehender Dachflächen liegt uns am Herzen. Die Schönheit von Denkmalschutz Objekten erhalten wir und nutzen unsere Falztechniken, um diese Dächer für Sie zu sanieren."
      />
    </b-container>
  </div>
</template>

<script>
import Stage from '@/components/units/Stage.vue'
import CardImg from '@/components/blocks/CardImg.vue'

export default {
  name: 'Roof',
  components: {
    Stage,
    CardImg
  },
  data() {
    return {
      stage_images: [
        require("@/assets/images/roofs.jpg")
      ]
    }
  },
  computed: {
    getMail() {
      const data = this.$store.getters.getContactData.email;
      return data
    }
  }
}
</script>

<style lang="scss">
  .card {
    margin-bottom: 1.5rem;
  }
</style>