<template>
  <div id="app">
    <header>
      <Header />
    </header>
    <main>
      <Mainpage />
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/units/Header.vue'
import Footer from '@/components/units/Footer.vue'
import Mainpage from '@/components/units/Mainpage.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Mainpage
  }
}
</script>

<style lang="scss">
</style>
