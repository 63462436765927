<template>
  <div class="plumber">
    <Stage :slides="stage_images" showInfo/>
    <b-container style="padding: 1.5rem 0">
      <b-card title="Unsere Leistungen im Überblick">
        <b-card-text>
          <b-list-group flush>
            <b-list-group-item>Wartung Ihrer Dachrinnen, Schornstein- und Gaubeneinfassungen aus Metall</b-list-group-item>
            <b-list-group-item>Reperaturarbeiten Ihrer Dachrinnen, Schornstein- und Gaubeneinfassungen aus Metall</b-list-group-item>
            <b-list-group-item>Erneuerung Ihrer Dachrinnen, Schornstein- und Gaubeneinfassungen aus Metall</b-list-group-item>
            <b-list-group-item>Gestaltung aus Kupfer oder Zink in Falztechnik als Sonderanfertigung</b-list-group-item>
          </b-list-group>
        </b-card-text>
        <b-card-text>Haben Sie Fragen? Kontaktieren Sie uns gerne telefonisch oder <a :href="'mailto:'+getMail"><small>per E-Mail</small></a>.</b-card-text>
      </b-card>
     <CardImg 
        :cardImg="require('@/assets/images/schornstein_schiefer.jpg')" 
        title="Schornsteinverkleidungen" 
        text="Wir verkleiden Ihren Schornstein entweder mit unseren bewährten Falztechniken in Kupfer oder Zink, oder mit hochwertiger Schieferverkleidung."
      />
      <CardImg 
        :cardImg="require('@/assets/images/custom_plumber02.jpg')" 
        title="Gaubeneinfassung" 
        text="Ihr Dach wird saniert und Ihre Gauben benötigen einen neuen Look? Hier haben Sie die Möglichkeit zwischen unseren Falztechniken, Resopal oder Schiefer zu wählen."
      />
      <CardImg 
        :cardImg="require('@/assets/images/custom_plumber03.jpg')" 
        title="Fassaden Verkleidung" 
        text="Eine Fassade kann mit unseren Falztechniken komplett oder in Teilen in Zink oder Kupfer, in Schiefer oder in Resopal verkleidet werden. "
      />
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/units/Stage.vue'
import CardImg from '@/components/blocks/CardImg.vue'

export default {
  name: 'Plumber',
  components: {
    Stage,
    CardImg
  },
  data() {
    return {
      stage_images: [
        require("@/assets/images/plumber01.jpg"),
        require("@/assets/images/plumber02.jpg")
      ]
    }
  },
  computed: {
    getMail() {
      const data = this.$store.getters.getContactData.email;
      return data
    }
  }
}
</script>

<style lang="scss">
  .card {
    margin-bottom: 1.5rem;
    & .list-group-item {
      padding: 0.5rem 0;
    }
  }
</style>
