import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    view: 'home',
    contact: {
      name: 'Bernd Mueller',
      street: '',
      housenumber: '',
      zipcode: '',
      city: 'Hamburg',
      tel: 'Tel.: 040 - 788 77 905',
      email: 'info@mueller-sanitaertechnik.com'
    },
    specialInfo: {
      status: false,
      title: 'Wieder geht ein aufregendes Jahr zu Ende',
      text: 'Wir danken unseren Kunden für ihr Vertrauen.\nVom 26.12.21 bis 06.01.22 machen wir wohlverdiente Betriebsferien und wünschen Ihnen eine schöne Zeit.\nBleiben Sie gesund!',
      asset: require('@/assets/images/xmas.png')
    },
    openingTimes: [
      {
        title: 'Montag - Donnerstag',
        times: '07:30 - 16:30'
      },
      {
        title: 'Freitag',
        times: '07:30 - 13:00'
      },
      {
        title: 'Samstag - Sonntag',
        times: 'geschlossen'
      }
    ],
    themes: [
      // Assets benötigen ein Seitenverhältnis von 1.5:1 z.B. 1280:853 (pexels size middle)
      {
        title: 'Unser ✰ ✰ ✰ ✰ Angebot',
        subtitle: 'Ihr Vorteil - Unsere Erfahrung - Alles aus einer Hand',
        text: 'Betreuung/Beschaffung aller Nebenleistungen wie Maler-, Maurer-, Tischler- und Elektroarbeiten mit befreundeten Fachfirmen',
        asset: require("@/assets/images/teamExperience.jpg"),
        link: ''
      },
      {
        title: 'Team sucht Verstärkung',
        text: 'Dachdecker und Anlagenmechaniker mit abgeschlossener Ausbildung treffen bei uns auf ein eingespieltes Team mit Liebe zu Qualität und Service',
        asset: require("@/assets/images/bm-team-search.jpg"),
        buttonLink: 'https://muellergmbh.perspectivefunnel.com/62616e60e1d8cc00199237d7/626fa7fe4af7530019b08d39/'
      },
      {
        title: 'Wissen erhalten',
        subtitle: 'Das Wissen unserer Altgesellen weitergeben',
        text: 'Wir legen großen Wert darauf, dass unsere jungen Kollegen auch nach ihrer Ausbildung weiter lernen, von unserer Erfahrung profitieren und regelmäßige Schulungen erhalten.',
        asset: require("@/assets/images/experiencedMan.jpg"),
        link: ''
      }
    ]
  },
  mutations: {
    setView(state, view) {
      state.view = view
    }
  },
  actions: {


  },
  modules: {
  },
  getters: {
    getView: state => {
      return state.view;
    },
    getContactData: state => {
      return state.contact;
    },
    getSpecialInfo: state => {
      return state.specialInfo;
    },
    getOpeningTimes: state => {
      return state.openingTimes;
    },
    getThemes: state => {
      return state.themes;
    }
  }
})
