<template>
  <b-card no-body class="overflow-hidden">
    <b-row no-gutters>
      <b-col md="5">
        <b-card-img :src="cardImg" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="7">
        <b-card-body :title="title" :sub-title="subTitle" :class="{'addititional-mb' : buttonText}">
          <b-card-text>
            {{text}}
          </b-card-text>
          <b-button 
            v-if="buttonText"
            class="card--link" 
            variant="outline-secondary" 
            :href="buttonLink"
            target="_blank"
          >
            {{buttonText}}
          </b-button>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    cardImg: {
      type: String,
      default: ''
    },
    title: {
      type:String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonLink: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  .card {
    border-radius: 0;
    &--img {
      border: 0;
      max-height: 400px;
    }
    &--link {
      position: absolute;
      bottom: 1rem;
    }
  }
  .addititional-mb {
    margin-bottom: 3rem;
  }
</style>