<template>
  <b-container style="padding: 1em;">
    <b-card bg-variant="light" title="Impressum">
      <b-card-text>
        <p>Bernd Mueller Bedachung-Klempnerei-Sanitär-Heizung GmbH</p>
        <p><span>Horner Landstraße 207</span><br/><span>22111 Hamburg</span></p>
        <p>Geschäftsführer: Bernd Mueller</p>
        <p><span>Kontakt:</span><span> Tel.: 040 - 788 77 905</span><br/><span>Fax: 040- 788 77 906</span><br/><span>Email: info@mueller-sanitaertechnik.com</span></p>
        <p><span>Registriert: Amtsgericht Hamburg</span>
          <span>Registrier-Nr: HRB-127 489</span><br/>
          <span>Steuer-Nr.: 46/706/01558</span>
        </p>
        <p>Inhaltlich verantwortlich: Bernd Mueller</p>
        <div>Datenschutz-Hinweis: Für Ihre Kontaktaufnahme über unsere angegebenen Kontaktdaten (Telefon, Fax, E-Mail, Postadresse) gilt hinsichtlich der Verarbeitung, Speicherung und ggf. Widerspruch unsere Datenschutzerklärung unter <span v-on:click="changeView('dataSec')"> www.mueller-sanitaertechnik.com/datenschutz</span>.</div>
      </b-card-text>
      <b-button v-on:click="changeView('dataSec')" variant="outline-dark">Datenschutz</b-button>
    </b-card>
  </b-container>
</template>

<script>
export default {
    name: 'Imprint',
    methods: {
    changeView(view){
      this.$store.commit('setView', view)
    }
  }
}
</script>