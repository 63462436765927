<template>
  <div>
    <HeaderStripes />
    <LogoContainer :tel="getData.tel" :email="getData.email"/>
    <Navigation />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderStripes from '@/components/elements/HeaderStripes.vue'
import LogoContainer from '@/components/blocks/LogoContainer.vue'
import Navigation from '@/components/blocks/Navigation.vue'

export default {
  name: 'App',
  components: {
    HeaderStripes,
    LogoContainer,
    Navigation
  },
  computed: {
    getData() {
      const data = this.$store.getters.getContactData;
      return data
    }
  }
}
</script>