<template>
  <div class="sanitary">
    <Stage :slides="stage_images" showInfo/>
    <b-container class="pt-4 pb-4">
      <b-card title="Unsere Leistungen im Überblick">
        <b-card-text>
          <b-list-group flush>
            <b-list-group-item>Sanierung und Modernisierung Ihrer Bäder und Küchen</b-list-group-item>
            <b-list-group-item>Seniorengerechte Badumbauten</b-list-group-item>
            <b-list-group-item>Erneuerung und Wartung Ihrer Gas-, Wasser- und Abwasserleitungen</b-list-group-item>
            <b-list-group-item>Beseitigung von Rohrverstopfungen</b-list-group-item>
          </b-list-group>
        </b-card-text>
        <b-card-text>Haben Sie Fragen? Kontaktieren Sie uns gerne telefonisch oder <a :href="'mailto:'+getMail"><small>per E-Mail</small></a>.</b-card-text>
      </b-card>
      <CardImg 
        :cardImg="require('@/assets/images/custom_bath02.jpg')" 
        title="Moderne Bäder" 
        text="Wir sanieren und modernisieren Ihr Bad ganz nach Ihren Vorstellungen und Wünschen."
      />
      <CardImg 
        :cardImg="require('@/assets/images/hsk_duschkabine.jpg')" 
        title="Starke Partner" 
        text="Unsere Sanitaerspezialisten verbauen Duschkabinen, Duschwannen und Trennwände von HSK, dem Badexperten."
        buttonLink="https://www.hsk.de/"
        buttonText="Mehr über HSK"
      />
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Stage from '@/components/units/Stage.vue'
import CardImg from '@/components/blocks/CardImg.vue'

export default {
  name: 'Sanitary',
  components: {
    Stage,
    CardImg
  },
  data() {
    return {
      stage_images: [
        require("@/assets/images/bathroom01.jpg"),
        require("@/assets/images/bathroom03.jpg")
      ]
    }
  },
  computed: {
    getMail() {
      const data = this.$store.getters.getContactData.email;
      return data
    }
  }
}
</script>

<style lang="scss">
  .card {
    & .list-group-item {
      padding: 0.5rem 0;
    }
  }
</style>
