<template>
  <b-col sm="12" md="6" class="mb-3">
    <b-card
      overlay
      :img-src="themeObject.asset"
      text-variant="white"
      :title="themeObject.title"
      :sub-title="themeObject.subtitle"
      sub-title-text-variant="light"
    >
      <b-card-text :class="{'additional-mb' : themeObject.buttonLink}">
        {{themeObject.text}}
      </b-card-text>
      <a :href="themeObject.buttonLink" target="_blank" v-if="themeObject.buttonLink">
        <b-button 
          class="card--link" 
          variant="outline-light" 
          :href="buttonLink"
          target="_blank"
          >
              Jetzt bewerben
        </b-button>
      </a>
    </b-card>
  </b-col>
</template>

<script>
export default {
  props: {
    buttonLink: {
      type: String,
      default: ''
    },
    themeObject: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-img-overlay {
    background: rgba(51,51,51,0.5);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & .card-title, .card-subtitle {
      width: 100%;
    }
    & .text-muted {
      // color: #d5d5d5 !important;
      width: 100%;
    }
    & .card-text {
      min-height: 50%;
    }
  }
  .card {
    @media screen and (max-width: 375px){
      height: 17rem;
      & .position-relative {
        height: 100%;
        overflow: hidden;
        & img {
          height: 100%;
          width: auto;
        }
      }
    }     
  }
</style>