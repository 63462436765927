<template>
  <b-carousel
    id="carousel-index"
    :interval="4000"
    indicators
    background="#ababab"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;"
    >
    <!-- Slides with image only -->
    <b-carousel-slide v-if="specialInfo.status" :img-src="specialInfo.asset" />
    <b-carousel-slide v-else v-for="image in slides" :key="image" :img-src="image" />

    <CardTimes v-if="!specialInfo.status && showInfo" :openingTimes="openingTimes"/>
    <CardInfo v-else-if="showInfo && specialInfo" :infoObject="specialInfo"/>
  </b-carousel>
</template>

<script>
import CardTimes from '@/components/elements/CardTimes.vue'
import CardInfo from '@/components/elements/CardInfo.vue'
export default {
  components: {
    CardTimes,
    CardInfo
  },
  props: {
    slides: Array,
    showInfo: Boolean
  },
  computed: {
    specialInfo() {
      const info = this.$store.getters.getSpecialInfo;
      return info
    },
    openingTimes() {
      return this.$store.getters.getOpeningTimes;
    }
  }
}
</script>