<template>
  <b-card :title="infoObject.title" class="specialInfo">
    <b-card-text>
      {{infoObject.text}}
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    infoObject: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.specialInfo {
  background: rgba(51,51,51,0.5);
  color: white;
  position: absolute;
    max-width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    border-radius: 0;
  @media screen and (min-width: 768px) {
    max-width: 50%;
    right: 1rem;
    top: 3rem;
    height: auto;
    max-height: 100%;
  }
  & .card-title {
    font-size: 1.25rem;
  }
  & .card-text {
    white-space: pre-line;
  }
}
</style>
