<template>
  <div class="footer">
    <b-list-group horizontal="sm" class="footer-nav">
      <b-list-group-item v-on:click="changeView('imprint')">Impressum</b-list-group-item>
      <b-list-group-item v-b-modal.modal-contact>Kontakt</b-list-group-item>
      <b-list-group-item v-on:click="changeView('dataSec')">Datenschutz</b-list-group-item>
    </b-list-group>
    <!-- Modal -->
    <b-modal id="modal-contact" title="Kontakt" hide-footer>
      <div class="modal-body">
        <div class="contact-data-bg">
          <p>Bernd Mueller GmbH</p>
          <p>Horner Landstraße 207<br />22111 Hamburg</p>

          <p>
            Tel: 040- 788 77 905<br />
            Fax: 040- 788 77 906<br />
            <a href="mailto:info@mueller-sanitärtechnik.com">E-Mail: info@mueller-sanitärtechnik.com</a>
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    changeView(view){
      this.$store.commit('setView', view)
    }
  }
}
</script>


<style lang="scss" scoped>
  a, a:hover, a:active, a:visited {
    text-decoration: none;
    color: #333;
  }
  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125)
  }
  .footer-nav {
    display: flex;
    justify-content: center;
    & .list-group-item {
      border: 0;
    }
  }
  .contact-data-bg {
    background-color: rgba(255,255,255,.8);
  }
</style>