<template>
  <div class="roof">
    <Stage :slides="stage_images" showInfo/>
    <b-container style="padding: 1.5rem 0">
      <b-card title="Unsere Leistungen im Überblick">
        <b-card-text>
          Wartung, Reperatur und Modernisierung Ihrer Heizungsanlage, <br /> 
          Solarthermie und Luftwärmepumpen. 
        </b-card-text>
        <b-card-text>Haben Sie Fragen? Kontaktieren Sie uns gerne telefonisch oder <a :href="'mailto:'+getMail"><small>per E-Mail</small></a>.</b-card-text>
      </b-card>
      <CardImg 
        :cardImg="require('@/assets/images/junkers9000i.jpeg')" 
        title="Starke Partner" 
        text="Wir verbauen für Sie moderne Heizungsanlagen und Heizkörper. Zu unseren langjährigen Partner gehört u.a. Bosch und Weishaupt."
      />
      <CardImg 
        :cardImg="require('@/assets/images/hts_edelstahl.jpg')" 
        title="Hochwertige Designs" 
        subTitle="Vielfältige Lösungen"
        text="Ob Sie sich für eine klassische Gasheizung, eine Luftwärmepumpe oder eine Infrarot Lösung entscheiden, wir bieten Ihnen stets das Angebot, das zu Ihnen und Ihrer Wohnsituation passt."
      />
      
    </b-container>
  </div>
</template>

<script>
import Stage from '@/components/units/Stage.vue'
import CardImg from '@/components/blocks/CardImg.vue'

export default {
  name: 'Heating',
  components: {
    Stage,
    CardImg
  },
  data() {
    return {
      stage_images: [
        require("@/assets/images/heating.jpg")
      ]
    }
  },
  computed: {
    getMail() {
      const data = this.$store.getters.getContactData.email;
      return data
    }
  }
}
</script>

<style lang="scss">
  .card {
    // margin-bottom: 1.5rem;
  }
</style>