<template>
 <b-navbar toggleable="sm" type="light" sticky>
    <b-navbar-toggle target="nav-collapse"> </b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav fluid="xl">
        <b-nav-item v-on:click="changeView('roof')" >Bedachung</b-nav-item>
        <b-nav-item v-on:click="changeView('plumber')">Klempnerei</b-nav-item>
        <b-nav-item v-on:click="changeView('sanitary')">Sanitär</b-nav-item>
        <b-nav-item v-on:click="changeView('heating')">Heizung</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  methods: {
    changeView(view){
      
      this.$store.commit('setView', view)
    }
  }
}
</script>

<style lang="scss" scoped>

.navbar {
  padding-left: 1rem;
  padding-right: 1rem;
  & .navbar-toggler {
    border: 0;
  }
}
.navbar-light {
  background: #fff;
  border-bottom: 1px solid darken(#fff, 10%);
  border-top: 1px solid darken(#fff, 10%);
  & .navbar-nav {
    width: 100%;
    max-width: 1900px;
    display: flex;
    justify-content: space-evenly;
    & .nav-link:focus, & .nav-link:hover {
      color: rgba(0, 0, 0, 0.95);
    }
  }
}
</style>